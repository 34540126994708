import { singleton } from "tsyringe";

import CommonSdk from "./common/CommonSdk";
import { SDK_READY } from "./constants";
import SdkEvent from "./events/SdkEvent";
import { SdkEventInterface } from "./events/SdkEventInterface";
import { SdkRegisteredModuleInterface } from "./SdkRegisteredModuleInterface";
import { SdkInitOptions } from "./types";

@singleton()
class Sdk {
    private readonly _emitter: EventTarget;

    private readonly _modules: Record<string, SdkRegisteredModuleInterface> =
        {};

    public readonly common?: CommonSdk;

    constructor(private readonly _initApp: (options: SdkInitOptions) => void) {
        this._emitter = new EventTarget();
    }

    public init(options: SdkInitOptions) {
        this._initApp(options);
    }

    public start() {
        if (!this.common) {
            throw new Error(
                "SDK cannot start without CommonSdk being registered"
            );
        }

        if (window && window.gw && !window.gw.sdk) {
            window.gw.sdk = this;
            document.dispatchEvent(new Event(SDK_READY));
        }
    }

    public on(type: string, callback: (event: Event) => void) {
        this._emitter.addEventListener(type, callback);
    }

    public off(type: string, callback: (event: Event) => void) {
        this._emitter.removeEventListener(type, callback);
    }

    public dispatch(event: SdkEventInterface) {
        this._emitter.dispatchEvent(
            new SdkEvent(event.type, { detail: event })
        );
    }

    public registerModule(module: SdkRegisteredModuleInterface) {
        module.register(this);
        this._modules[module.constructor.name] = module;
    }
}

export default Sdk;
