export const SDK_READY = "gwSdkReady";

export enum COMMON_SDK_EVENTS {
    WIDGET_INJECTED = "widgetInjected",
    WIDGET_OPENED = "widgetOpened",
    WIDGET_CLOSED = "widgetClosed",
}

export enum COMMON_SDK_METHODS {
    SET_GLOBAL_PRODUCT_ID = "setGlobalProductId",
    SET_GLOBAL_VARIANT_ID = "setGlobalVariantId",
}

export enum METHOD_STATUSES {
    SUCCESS = "success",
}

export enum ERROR_CODES {
    GENERAL_ERROR = "generalError",
    NO_SUBSCRIBERS = "noSubscribers",
    INSUFFICIENT_ARGS = "insufficientArgs",
    TIMEOUT_ERROR = "timeoutError",
}

export const PROMISE_TIMEOUT_MS = 15000;
