import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import CommonSdk from "./common/CommonSdk";
import SdkMethodsCollector from "./methods/SdkMethodsCollector";
import Sdk from "./Sdk";
import { sdkMethodsCollectorToken, sdkToken } from "./tokens";
import { SdkInitOptions } from "./types";

export class SdkModule {
    registerProviders(
        di: DependencyContainer,
        initApp: (options: SdkInitOptions) => void
    ) {
        di.register(sdkMethodsCollectorToken, {
            useFactory: instanceCachingFactory(() => new SdkMethodsCollector()),
        });
        di.register(sdkToken, {
            useFactory: instanceCachingFactory(() => {
                return new Sdk(initApp);
            }),
        });
        di.resolve(sdkToken).registerModule(
            new CommonSdk(
                di.resolve(ProductIdFromSdkProvider),
                di.resolve(ProductVariantIdFromSdkProvider)
            )
        );
        di.resolve(sdkToken).start();
    }
}
