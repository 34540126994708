import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import BrandingsApiServiceInterface from "./api_services/BrandingsApiServiceInterface";
import { BrandingModule } from "./BrandingModule";
import BrandingProviderInterface from "./providers/BrandingProviderInterface";

export const brandingModuleToken = Symbol(
    "brandingModuleToken"
) as InjectionToken<BrandingModule>;

export const brandingsAuthApiClientToken = Symbol(
    "brandingsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const brandingsApiServiceToken = Symbol(
    "brandingsApiServiceToken"
) as InjectionToken<BrandingsApiServiceInterface>;

export const brandingProviderToken = Symbol(
    "brandingProviderToken"
) as InjectionToken<BrandingProviderInterface>;
