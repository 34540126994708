import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";

import { COMMON_SDK_METHODS, METHOD_STATUSES } from "../constants";
import { SdkMethodsInterface } from "../methods/SdkMethodsInterface";
import { SuccessResult } from "../types";

class CommonSdkMethods implements SdkMethodsInterface<COMMON_SDK_METHODS> {
    constructor(
        private readonly _productIdProvider: ProductIdFromSdkProvider,
        private readonly _variantIdProvider: ProductVariantIdFromSdkProvider
    ) {}

    public setGlobalProductId(productId: number) {
        return new Promise<SuccessResult>((resolve) => {
            this._productIdProvider.setProductId(productId);
            resolve({ status: METHOD_STATUSES.SUCCESS });
        });
    }

    public setGlobalVariantId(variantId: number) {
        return new Promise<SuccessResult>((resolve) => {
            this._variantIdProvider.setVariantId(variantId);
            resolve({ status: METHOD_STATUSES.SUCCESS });
        });
    }
}

export default CommonSdkMethods;
