import { enums, number, object, string } from "superstruct";

const WishlistBrandingSchema = object({
    wishlistThemeSettings: object({
        primaryColor: string(), // hex
        secondaryColor: string(), // hex
    }),
    wishlistIconSettings: object({
        heartColor: string(), // hex
        backgroundColor: string(), // black | white (hex)
    }),
    wishlistButtonSettings: object({
        color: enums(["primaryColor", "secondaryColor"]), // primary | secondary
        borderRadius: number(), // number
        fontColor: string(), // black | white (hex)
        style: enums(["outlined", "filled"]), // outline | filled
    }),
    wishlistPopUpWidgetAndPageSettings: object({
        popupWidgetPosition: enums(["L", "R"]), // left | right
        launcherBackgroundColor: string(), // black | white (hex)
        launcherFontColor: string(), // black | white (hex)
        launcherIconBackgroundColor: enums(["primaryColor", "secondaryColor"]), // primary | secondary
        buttonsColor: enums(["primaryColor", "secondaryColor"]), // primary | secondary
        buttonsFontColor: string(), // black | white (hex)
        buttonsStyle: enums(["outlined", "filled"]), // outline | filled
        buttonsBorderRadius: number(), // number
        linkColor: enums(["primaryColor", "secondaryColor"]), // primary | secondary
    }),
});

export default WishlistBrandingSchema;
