import { CURRENT_APP_QUERY_PARAMETER } from "@/constants/current_app";
import {
    eventsCollectorToken,
    globalLoggerToken,
    gwStorefrontAppInfoToken,
    locationControllerToken,
    tokenManagerToken,
} from "@/tokens";
import { AnalyticsEventsFactory } from "@lib/AnalyticsEventsCollector/AnalyticsEventsFactory";
import ApiClient from "@lib/ApiClient";
import ApiClientWithMetrics from "@lib/ApiClient/ApiClientWithMetrics";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import BrandingsApiService from "./api_services/BrandingsApiService";
import BrandingFromApiProvider from "./providers/BrandingFromApiProvider";
import BrandingFromJsonProvider from "./providers/BrandingFromJsonProvider";
import {
    brandingProviderToken,
    brandingsApiServiceToken,
    brandingsAuthApiClientToken,
} from "./tokens";

export class BrandingModule {
    registerProviders(di: DependencyContainer) {
        di.register(brandingsAuthApiClientToken, {
            useFactory: instanceCachingFactory(() => {
                return new AuthApiClient(
                    new ApiClientWithMetrics(
                        new ApiClient(
                            `${
                                di.resolve(gwStorefrontAppInfoToken)
                                    .appProxyPrefix
                            }/branding-service/v2/storefront-api/`,
                            undefined,
                            {
                                [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                    gwStorefrontAppInfoToken
                                ).currentApp,
                            },
                            di.resolve(globalLoggerToken)
                        ),
                        di.resolve(eventsCollectorToken),
                        di.resolve(locationControllerToken),
                        di.resolve(AnalyticsEventsFactory)
                    ),
                    di.resolve(tokenManagerToken)
                );
            }),
        });
        di.register(brandingsApiServiceToken, {
            useFactory: instanceCachingFactory((di) => {
                return new BrandingsApiService(
                    di.resolve(brandingsAuthApiClientToken),
                    di.resolve(globalLoggerToken)
                );
            }),
        });
    }

    registerFromJsonProviders(di: DependencyContainer) {
        di.register(brandingProviderToken, {
            useFactory: instanceCachingFactory((di) => {
                return new BrandingFromJsonProvider(
                    di.resolve(globalLoggerToken)
                );
            }),
        });
    }

    registerFromApiProviders(di: DependencyContainer) {
        di.register(brandingProviderToken, {
            useFactory: instanceCachingFactory((di) => {
                return new BrandingFromApiProvider(
                    di.resolve(brandingsApiServiceToken),
                    di.resolve(globalLoggerToken)
                );
            }),
        });
    }
}
