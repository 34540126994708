import { singleton } from "tsyringe";

import BaseSdkMethodPayload from "./BaseSdkMethodPayload";

type SubscriberCallback = (payload: BaseSdkMethodPayload) => void;

@singleton()
class SdkMethodsCollector {
    private _subscribers: {
        [methodName: string]: SubscriberCallback[];
    };

    constructor() {
        this._subscribers = {};
    }

    addSubscriber<T extends BaseSdkMethodPayload>(
        methodName: T["methodName"],
        callback: (payload: T) => void
    ): void {
        if (!this._subscribers[methodName]) {
            this._subscribers[methodName] = [];
        }
        this._subscribers[methodName].push(callback as SubscriberCallback);
    }

    deleteSubscriber<T extends BaseSdkMethodPayload>(
        methodName: T["methodName"],
        callback: (payload: T) => void
    ): void {
        if (this._subscribers[methodName]) {
            this._subscribers[methodName] = this._subscribers[
                methodName
            ].filter((cb) => cb !== callback);
        }
    }

    hasSubscribers(methodName: string): boolean {
        return this._subscribers[methodName]?.length > 0;
    }

    notifySubscribers(payload: BaseSdkMethodPayload): void {
        this._subscribers[payload.methodName]?.forEach((callback) =>
            callback(payload)
        );
    }
}

export default SdkMethodsCollector;
