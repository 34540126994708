import { literal, nullable, object, string, union } from "superstruct";

const BrandingThemeColorSchema = union([
    literal("primaryColor"),
    literal("secondaryColor"),
]);

const RewardsBrandingSchema = object({
    rewardsPopUpWidget: object({
        programName: string(),
        theme: object({
            primaryColor: string(),
            secondaryColor: string(),
        }),
        banner: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: string(),
            image: nullable(string()),
        }),
        buttons: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: string(),
        }),
        content: object({
            iconsThemeColor: BrandingThemeColorSchema,
            linksThemeColor: BrandingThemeColorSchema,
        }),
        launcher: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: string(),
            widgetPosition: string(),
        }),
    }),
});

export default RewardsBrandingSchema;
